import React, { useLayoutEffect } from "react";

import { TwoColumnPage, ThreeColumnPage } from "../components/Page";
import barnJpg from "./../images/barn.jpg"
import womanJpg from "./../images/woman.jpg"
import travelJpg from "./../images/travel.jpg"
import screenshotPng from "./../images/screenshots/Screenshot.png"
import arrowPng from "./../images/arrow.png"
import checkPng from "./../images/check.png"
import beltBlackPng from "./../images/belt_black.png"
import beltBluePng from "./../images/belt_blue.png"
import beltWhitePng from "./../images/belt_white.png"
import heroPng from "./../images/player-0-sv-sr.png"

import "./student.scss"

const Student = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const baseUrl = process.env.REACT_APP_DOMAIN_NAME ?? '';
    const gameUrl = baseUrl.includes("localhost") ? baseUrl + ':3200' : `https://spel.${baseUrl}`;
    return (<>
        <TwoColumnPage style={{ backgroundColor: 'gray' }}>
            <div className="column leftColumn bigCol">
                <img src={barnJpg} alt="Barn som spelar" />
            </div>
            <div className="column rightColumn smallCol textColumn primaryText" style={{ backgroundColor: 'gray' }}>
                <h3>Grammatik är det roligaste som finns</h3>
            </div>
        </TwoColumnPage>
        <ThreeColumnPage >
            <div className="column leftColumn m-padding">
                <img src={checkPng} alt="checkmark" />
                <h3>Belönande</h3>
                <p>Hitta skatter, hjälp de behövande, besegra farorna.</p>
            </div>
            <div className="column middleColumn m-padding">
                <img src={checkPng} alt="checkmark" />
                <h3>Utmanande</h3>
                <p>Lös utmaning efter utmaning. Allt eftersom du lär dig mer, blir spelet svårare.</p>
            </div>
            <div className="column rightColumn m-padding">
                <img src={checkPng} alt="checkmark" />
                <h3>Roligt</h3>
                <p>Ett spel som får dig att känna att grammatik är det roligaste som finns.</p>
            </div>
        </ThreeColumnPage>
        <TwoColumnPage className="primary-background">
            <div className="column leftColumn bigCol primary-background">
                <a href={gameUrl} >
                    <img src={screenshotPng} alt="Skärmbild från elevert" />
                </a>
            </div>
            <div className="column rightColumn smallCol m-padding primary-background">
                <span className="helper"></span>
                <a href={gameUrl} style={{ display: "inline-block", maxWidth: "30%" }}><img src={arrowPng} alt="Golden arrow" /></a>
            </div>
        </TwoColumnPage>
        <TwoColumnPage className="noMargin">
            <div className="column leftColumn" >
                <img src={womanJpg} alt="kvinna som spelar" />
            </div>
            <div className="column rightColumn textColumn">
                <h3 className="primary-color">Testa själv</h3>
                <p>I spelet kan man lära sig alla grammatikens delar, från ordklasser & satslära till rättstavning & ordspråk.</p>
                <a href={gameUrl} style={{ display: "inline-block", maxWidth: "15%" }}><img src={arrowPng} alt="Golden arrow" /></a>
            </div>
        </TwoColumnPage>
        <TwoColumnPage className="noMargin">
            <div className="column leftColumn textColumn second">
                <h3 className="primary-color">Lär dig var du vill, när du vill</h3>
                <p>Spela under lektionerna, som hemarbete, eller varför inte i bilen?</p>
            </div>
            <div className="column rightColumn first" >
                <img src={travelJpg} alt="Pojke som spelar i bil" />
            </div>
        </TwoColumnPage>
        <TwoColumnPage>
            <div className="column leftColumn gridColumn background-primary"  >
                <img style={{ gridColumn: 2, gridRow: 5 }} src={heroPng} alt="hjälte" />
                <img style={{ gridColumn: 3, gridRow: 4 }} src={beltWhitePng} alt="Vit bälte" />
                <img style={{ gridColumn: 4, gridRow: 3 }} src={beltBluePng} alt="Blått bälte" />
                <img style={{ gridColumn: 5, gridRow: 2 }} src={beltBlackPng} alt="Svart bälte" />
                <div style={{ gridColumn: 3, gridRow: 5, borderLeft: '5px solid #f67535', borderTop: '5px solid #f67535' }}></div>
                <div style={{ gridColumn: 4, gridRow: 4, borderLeft: '5px solid #f67535', borderTop: '5px solid #f67535' }}></div>
                <div style={{ gridColumn: 5, gridRow: 3, borderLeft: '5px solid #f67535', borderTop: '5px solid #f67535' }}></div>
            </div>
            <div className="column rightColumn textColumn">
                <h3 className="primary-color">Vad kan du?</h3>
                <p>Elevert har uppgifter med olika svårighet för att passa spelare på olika nivåer; alltifrån ungdomar i grundskolan och  gymnasiet, till vuxna som läser SFI eller är allmänt språkintresserade.</p>
                <p>Prova själv, så får du se vilken koll du har på svenskan!</p>
                <a href={gameUrl} style={{ display: "inline-block", maxWidth: "18%", marginTop: '25px'}}><img src={arrowPng} alt="Golden arrow" /></a>
            </div>
        </TwoColumnPage>
    </>)
}

export default Student;