import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styles from './CookieConsent.module.scss';
import ReactPixel from 'react-facebook-pixel';

//TODO: Handle calendly integration
const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookie-consent']);
  const [showBanner, setShowBanner] = useState(!cookies['cookie-consent']);
  // const [showBanner, setShowBanner] = useState(!sessionStorage.getItem('cookie-consent'));

  useEffect(() => {
    if (cookies['cookie-consent'] === 'accepted') {
      ReactPixel.grantConsent();
    }
  })

  const handleAccept = () => {
    setCookie('cookie-consent', 'accepted', { path: '/' });
    ReactPixel.grantConsent();
    // sessionStorage.setItem('cookie-consent', 'accepted');
    setShowBanner(false);
  };
  const handleDecline = () => {
    ReactPixel.revokeConsent();
    // setCookie('cookie-consent', 'decline', { path: '/' });
    // sessionStorage.setItem('cookie-consent', 'decline');
    setShowBanner(false);
  };

  if (showBanner) {
    return (
      <div className={`cookie-consent ${styles.cookieConsentBanner}`}>
        <p className={styles.cookieConsentBannerText}>
          Vi använder kakor (cookies) för att förbättra webbplatsen och din användarupplevelse.
        </p>
        <button className={`${styles.cookieConsentBannerButton} ${styles.accept}`} onClick={handleAccept}>Acceptera</button>
        <button className={`${styles.cookieConsentBannerButton} ${styles.decline}`} onClick={handleDecline}>Neka</button>
        <a href="/integritetspolicy"><button className={`${styles.cookieConsentBannerButton} ${styles.info}`} >Läs mer</button></a>
      </div>
    );
  }

  return null;
};

export default CookieConsent;
