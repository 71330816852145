import React, { useLayoutEffect, useState } from "react";
import { OneColumnPage, TwoColumnPage } from "../components/Page";
import trophySvg from "./../images/trophy.svg";
import checkPng from "./../images/check.png"
import arrowPng from "./../images/arrow.png"
import bagJpg from "./../images/bag.jpg"
import gdprJpg from "./../images/gdpr.jpg"
import techJpg from "./../images/tech.jpg"
import lgr22Png from "./../images/lgr22.png"
import "./teacher.scss"
import screenshotPng from "./../images/screenshots/Screenshot.png"
import homeworkGif from "./../images/screenshots/New folder/elevert-homework.gif";
import trackingAreasGif from "./../images/screenshots/New folder/elevert-trackingareas.gif";
import teacherRegGif from "./../images/screenshots/elevert-teacherreg.gif"
import { InlineWidget } from "react-calendly";
import Carousel from "../components/Carousel";
import handleModal from "../components/Modal";


const Teacher = () => {
    const [modal, setModal] = useState();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const baseUrl = process.env.REACT_APP_DOMAIN_NAME ?? '';
    const schoolUrl = baseUrl.includes("localhost") ? baseUrl + ':3100' : `https://skola.${baseUrl}`;
    const pageSettings = {
        // backgroundColor: 'f6d635',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        // primaryColor: '00a2ff',
        // textColor: '4d5055'
    }

    return (<>
        <TwoColumnPage className="primary-background">
            <div className="column leftColumn textColumn">
                <h1 className="primary-color">Grammatik är roligt!</h1>
            </div>
            <div className="column rightColumn">
                <img src={screenshotPng} alt="Skärmbild från elevert" />
            </div>
        </TwoColumnPage>
        <TwoColumnPage className="primary-background">
            <div className="column leftColumn">
                <h5>Kom snabbt igång som lärare</h5>
                <img className="primary-color" src={teacherRegGif} style={{ border: "2px solid", borderRadius: "3px" }} alt="lärarregistrering gif" />
            </div>
            <div className="column rightColumn padding" style={{ textAlign: 'center' }}>
                <span className="helper"></span>
                <a href={schoolUrl} style={{ display: "inline-block", maxWidth: "40%" }}>
                    <img src={arrowPng} alt="Golden arrow" />
                </a>
            </div>
        </TwoColumnPage>
        <OneColumnPage className="no-padding primary-background hide-mobile">
                <h1 className="primary-color">Boka introduktion</h1>
                <InlineWidget url="https://calendly.com/grammatik-med-elevert/30min?hide_gdpr_banner=1" pageSettings={pageSettings} styles={{ width: '1000px', height: '750px', alignSelf: 'center' }} />
        </OneColumnPage>
        <TwoColumnPage className="no-padding primary-background hide-desktop" >
            <div className="column leftColumn textColumn">
                <h1 className="primary-color">Boka introduktion</h1>
            </div>
            <div className="column rightColumn">
                <InlineWidget url="https://calendly.com/grammatik-med-elevert/30min?hide_gdpr_banner=1" pageSettings={pageSettings} styles={{ height: '500px', alignSelf: 'center' }} />
            </div>
        </TwoColumnPage>
        {modal !== undefined && modal}
        <OneColumnPage className="no-padding primary-background">
            <Carousel {...{modal, setModal, handleModal}} >
                <div className="elevert-w-100" >
                    <h5>Skapa anpassade hemuppgifter och prov</h5>
                    <img onClick={() => {}} src={homeworkGif} alt="homework gif" />
                </div>
                <div className="elevert-w-100" >
                    <h5>Se med vilka områden eleverna behöver extra stöd</h5>
                    <img src={trackingAreasGif} alt="tracking area gif" />
                </div>
                <div className="elevert-w-100" >
                    <h5>Se exakt vilka frågor eleverna besvarar, och hur</h5>
                    <img src={trackingAreasGif} alt="tracking tasks gif" />
                </div>
            </Carousel>
        </OneColumnPage>
        <TwoColumnPage>
            <div className="column leftColumn m-padding">
                <span className="helper"></span>
                <img src={trophySvg} alt="trophy" />
            </div>
            <div className="column rightColumn m-padding" style={{ textAlign: 'left' }}>
                <div className="imageRow"><img className="smallImg" src={checkPng} alt="checkmark" /><span>Lätt att komma igång</span></div>
                <div className="imageRow"><img className="smallImg" src={checkPng} alt="checkmark" /><span>Anpassningsbart</span></div>
                <div className="imageRow"><img className="smallImg" src={checkPng} alt="checkmark" /><span>Enkelt följa upp elever</span></div>
                <div className="imageRow"><img className="smallImg" src={checkPng} alt="checkmark" /><span>Lätt att ge feedback</span></div>
                <div className="imageRow"><img className="smallImg" src={checkPng} alt="checkmark" /><span>Stödjer GDPR</span></div>
                <div className="imageRow"><img className="smallImg" src={checkPng} alt="checkmark" /><span>Integrerat med Google & Microsoft</span></div>
            </div>
        </TwoColumnPage>
        <TwoColumnPage>
            <div className="column leftColumn bigCol">
                <img src={bagJpg} alt="man with bag" />
            </div>
            <div className="column rightColumn smallCol textColumn">
                <h3 className="primary-color">Snabbstartat</h3>
                <p>Enkelt att komma igång. Antingen på egen hand, var och en för sig, eller genom en personlig inbjudan dig. Eleverna använder sina Google och/eller Microsoft- konton för att logga in</p>
            </div>
        </TwoColumnPage>
        <TwoColumnPage className="noMargin">
            <div className="column leftColumn" >
                <img src={gdprJpg} alt="Eu GDPR" />
            </div>
            <div className="column rightColumn textColumn">
                <h3 className="primary-color">GDPR</h3>
                <p>Elevert följer GDPR in i minsta detalj.</p>
                <p>Vi kompromissar inte med varken din eller barnens personliga integritet.</p>
            </div>
        </TwoColumnPage>
        <TwoColumnPage className="noMargin">
            <div className="column leftColumn second textColumn">
                <h3 className="primary-color">Befintlig utrustning</h3>
                <p>Vi har varit noga med att spelet inte skall ställa några speciella krav på hårdvaran. Det fungerar lika bra på gamla som nya datorer och plattor.</p>
            </div>
            <div className="column rightColumn first" >
                <img src={techJpg} alt="pile of various tech devices" />
            </div>
        </TwoColumnPage>
        <TwoColumnPage>
            <div className="column leftColumn imgColumn" >
                <img src={lgr22Png} alt="skolverket lgr22" />
            </div>
            <div className="column rightColumn textColumn">
                <h3 className="primary-color">Lgr22</h3>
                <p>Vi hjälper dig hjälpa eleverna klara läroplanens kunskapsmål.</p>
            </div>
        </TwoColumnPage>
    </>)

}

export default Teacher;