import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/stylesheet.scss";
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

const tagManagerArgs = {
  gtmId: 'GTM-T9KW5HS',
  // gtmId: 'GTM-5HX5GGG',
}

TagManager.initialize(tagManagerArgs)

const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

ReactPixel.init('641978474781916', undefined, pixelOptions);
ReactPixel.revokeConsent();
ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
