import { OneColumnPage, TwoColumnPage } from "../components/Page";
import { useCookies } from 'react-cookie';
import './integrityPolicy.scss'
import cookiesPng from "./../images/cookies.png"
import ReactPixel from 'react-facebook-pixel';
import { useState } from "react";


const IntegrityPolicy = () => {
    const [isDeclined, setDeclined] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-consent']);

    const handleAccept = () => {
        setCookie('cookie-consent', 'accepted', { path: '/' });
        ReactPixel.grantConsent();
        setDeclined(false)
        console.log('cookie', cookies['cookie-consent'])
        window.location.reload();
    };

    const handleDeny = () => {
        removeCookie('cookie-consent', { path: '/' });
        ReactPixel.revokeConsent();
        setDeclined(true)
        window.location.reload();
    };

    return <>
        <OneColumnPage noFade={true} style={{ textAlign: 'left' }}>
            <h3 className="title">Integritetspolicy</h3>
            <p className="content">
                Vi samlar in så lite personlig information om våra användare som möjligt. Vi använder kakor (cookies) för att förbättra webbplatsen och göra upplevelsen för dig som besöker den bättre och smidigare. En kaka är en kort textfil som webbplatsen du besöker ber om att få spara på din dator, mobil eller surfplatta under en bestämd tid.
            </ p>
        </OneColumnPage>
        <OneColumnPage className="noMargin" noFade={true} style={{ textAlign: 'left' }}>
            <h3 className="title">Bestäm vad du vill dela</h3>
            <p className="content">
                <div className="button" onClick={handleAccept} style={{ backgroundColor: '#35b6f6' }}>Godkänn alla kakor</div>
            </p>
        </OneColumnPage>
        <TwoColumnPage className="noMargin" noFade={true} style={{ textAlign: 'left' }}>
            <div className="column leftColumn imgColumn smallCol">
                <img className="" src={cookiesPng} style={{ width: '60%' }} alt="Cookies" />
            </div>
            <div className="column rightColumn textColumn bigCol">
                <h4 className="title">Nödvändiga kakor</h4>
                <p className="content leftAlign">
                    Nödvändiga kakor krävs för att webbplatsen ska fungera. Det kan vara funktioner som gör att du kan fylla i formulär, inställningarna för dina personliga preferenser eller inloggning. I din webbläsare kan du välja att blockera eller ställa in så du blir varnad för dessa kakor, men tänk på att hela eller delar av webbplatsen inte fungerar då. Nödvändiga kakor lagrar inte någon personligt identifierbar information.
                </p>
                <div className="button" style={{ width: '135px' }} disabled>Ej valbart</div>
            </div>
        </TwoColumnPage>
        <TwoColumnPage className="noMargin" noFade={true} style={{ textAlign: 'left' }}>
            <div className="column leftColumn imgColumn smallCol">
                <img className="" src={cookiesPng} style={{ width: '60%' }} alt="Cookies" />
            </div>
            <div className="column rightColumn textColumn bigCol leftAlign">
                <h4 className="title">Analyskakor</h4>
                <div className="content leftAlign">
                    <p>
                        Vi jobbar hårt för att tillhandahålla en så bra tjänst som möjligt. Ett viktigt verktyg för oss är att kunna se vilka funktioner som används, hur ofta och på vilket sätt.
                        Analyskakor gör det möjligt för oss att samla in denna information. På så sätt kan vi förbättra din upplevelse – vi kan förbättra tekniska aspekter men även innehållet på sajten. Om du inte tillåter dessa kakor vet vi inte när du har besökt vår webbplats eller vilka sidor du interagerat med.
                    </p>
                    <div className="" style={{ textAlign: 'center', marginBottom: '10px', display: 'flex' }}>
                        <div className="button" onClick={handleAccept} style={{ marginRight: '20px', backgroundColor: '#B6F635', width: '135px' }}>Godkänn</div>
                        <div className="button" onClick={handleDeny} style={{ backgroundColor: !isDeclined ? '#f63555' : 'lightgray', width: '135px' }} disabled={isDeclined}>Neka</div>
                    </div>
                    <p className="content leftAlign">
                        Skapar du ett användarkonto i vår applikation så samtycker du automatiskt till att vi samlar in information om vilka funktioner du använder i syfte att förbättra applikationen.
                    </p>
                </div>
            </div>
        </TwoColumnPage>

    </>
}

export default IntegrityPolicy;