import React from "react";
import "./footer.scss"

const Footer = () => {


    const isDevelopment = () => {
        return window.location.origin.includes('localhost') || window.location.origin.includes('test')
    }

    return (
        <div className={`footer ${isDevelopment() ? 'isDevelopment' : ''}`}>
            <div className={`footerContent`}>
                Copyright © {(new Date().getFullYear())} Elevert.se
            </div>
        </div>
    )
}

export default Footer;