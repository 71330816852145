import React, { useEffect, useState } from "react";
import "./Carousel.scss";


function Carousel(props) {
    const [children, setChildren] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const onClick = (index) => {
            const items = document.querySelectorAll('.elevert-carousel-item');
            const imgElement = items[index].querySelector('img');
            props.setModal(props.handleModal(imgElement, props.setModal))

        }
        const temp = props.children.map((child, index) => {
            return index === activeIndex ? <div key={index} onClick={() => onClick(index)} className="elevert-carousel-item active">{child}</div>
                : <div key={index} onClick={() => onClick(index)} className="elevert-carousel-item">{child}</div>
        })

        setChildren(temp);
    }, [])

    const changeImage = (isNext) => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        const actInd = activeIndex;
        let newInd = isNext ? actInd + 1 : actInd - 1;
        newInd = newInd % children.length;
        if (newInd < 0) {
            newInd = newInd * -1;
        }
        const timeout = 600;
        const items = document.querySelectorAll('.elevert-carousel-item');
        if (isNext) {
            items[actInd].classList.add('elevert-carousel-item-start');
            setTimeout(() => {
                items[newInd].classList.add('elevert-carousel-item-next');
                setTimeout(() => {
                    items[newInd].classList.add('elevert-carousel-item-start');
                    setTimeout(() => {
                        items[newInd].classList.add('active');
                        items[newInd].classList.remove('elevert-carousel-item-next');
                        items[newInd].classList.remove('elevert-carousel-item-start');
                        items[actInd].classList.remove('active');
                        items[actInd].classList.remove('elevert-carousel-item-start');
                        setActiveIndex(newInd);
                        setIsTransitioning(false);
                    }, timeout)
                }, 50)
            }, 20)
        } else if (!isNext) {
            items[actInd].classList.add('elevert-carousel-item-end');
            setTimeout(() => {
                items[newInd].classList.add('elevert-carousel-item-prev');
                setTimeout(() => {
                    items[newInd].classList.add('elevert-carousel-item-end');
                    setTimeout(() => {
                        items[newInd].classList.add('active');
                        items[newInd].classList.remove('elevert-carousel-item-prev');
                        items[newInd].classList.remove('elevert-carousel-item-end');
                        items[actInd].classList.remove('active');
                        items[actInd].classList.remove('elevert-carousel-item-end');
                        setActiveIndex(newInd);
                        setIsTransitioning(false);
                    }, timeout)
                }, 1)
            }, 1)
        }
    }

    return (<>
        <div className="elevert-carousel">
            <div className="elevert-carousel-inner">
                {children.map((child) => child)}
            </div>
            <button className="elevert-carousel-prev" onClick={() => changeImage(false)}><div>&lt;</div></button>
            <button className="elevert-carousel-next" onClick={() => changeImage(true)}><div>&gt;</div></button>
        </div>
    </>)
}

export default Carousel;