import './App.scss';
// import './stylesheet.scss'
import { BrowserRouter } from "react-router-dom";

import Routes from './Routes';
import Footer from './components/Footer';
import Header from './components/Header';
// import CookieConsent from './components/cookie_consent/CookieConsent';
import CookieConsent from './components/cookie_consent/CookieConsent'



function App() {
  document.querySelector('.browser_not_supported')?.remove()

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes />
        <Footer />
        <CookieConsent />
      </BrowserRouter>
    </div>
  );
}

export default App;
