import React from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom'

// import { ReactComponent as Logo3 } from './../images/logo3.svg';
import { ReactComponent as Logo } from './../images/logo.svg';

import "./header.scss"



function Header() {
    const baseUrl = process.env.REACT_APP_DOMAIN_NAME ?? '';
    const gameUrl = baseUrl.includes("localhost") ? baseUrl + ':3200' : `https://spel.${baseUrl}`;
    return (<>
        <div className="emptyHeader" style={{ display: 'flex' }}></div>
        <Navbar className="bg-primary header" >
            <Container>
                <Nav className="brands">
                    <Navbar.Brand href="/Student">
                        <Logo
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Brand href="/Student"><h3>Elevert</h3></Navbar.Brand>
                </Nav>
                <Nav className="justify-content-end" activeKey={window.location.pathname} >
                    <Nav.Item>
                        <NavLink to="/Student"> Elev</NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink to="/Teacher">Lärare</NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink to={gameUrl} >Spela</NavLink>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar >
    </>)
}


export default Header;