import React from "react"
import "./Page.scss"
import FadeInSection from "./Fadein"

export const OneColumnPage = (props) => {
    return <>{!props.noFade ? <FadeInSection>
        <div className={"elev-card elev-card-margin oneColumn " + (!!props.className ? props.className : '')} style={(!!props.style ? props.style : {})} >
            {props.children}
        </  div>
    </FadeInSection> : <div className={"elev-card elev-card-margin oneColumn " + (!!props.className ? props.className : '')} style={(!!props.style ? props.style : {})} >
        {props.children}
    </div>}</>
}

export const TwoColumnPage = (props) => {
    return <>{!props.noFade ? <FadeInSection>
        <div className={"elev-card elev-card-margin twoColumn " + (!!props.className ? props.className : '')} style={(!!props.style ? props.style : {})}>
            {props.children}
        </div>
    </FadeInSection> : <div className={"elev-card elev-card-margin twoColumn " + (!!props.className ? props.className : '')} style={(!!props.style ? props.style : {})}>
        {props.children}
    </div>}</>
}

export const ThreeColumnPage = (props) => {

    return <>{!props.noFade ? <FadeInSection>
        <div className={"elev-card elev-card-margin threeColumn " + (!!props.className ? props.className : '')} style={(!!props.style ? props.style : {})}>
            {props.children}
        </div>
    </FadeInSection> : <div className={"elev-card elev-card-margin threeColumn " + (!!props.className ? props.className : '')} style={(!!props.style ? props.style : {})}>
        {props.children}
    </div>}</>
}
