import React from 'react';
import { Routes, Route } from 'react-router-dom'

import Student from "./pages/Student";
import Teacher from "./pages/Teacher";
import IntegrityPolicy from './pages/IntegrityPolicy';

function AppRoutes() {
    return (
        <main>
            <Routes>
                <Route path="/" element={<Teacher />} />
                <Route path="Student" element={<Student />} />
                <Route path="Teacher" element={<Teacher />} />
                <Route path="/integritetspolicy" element={<IntegrityPolicy />} />
            </Routes>
        </main>
    )
}

export default AppRoutes;